<template>
  <div>
    <!-- <Header></Header> -->
    <div class="banner" style="width: 100%; height: auto;">
      <img :src="Src_" alt="" style="width:100%; height:100%;">
    </div>
    <div class="main">
      <div class="main-zz">
        <div class="text">
          <p style="margin: 0; font-size: 30px; font-weight: bolder">
            联系我们：
          </p>
          <p style="font-size: 22px; font-weight: bolder">
            咨询电话：400-602-9998
          </p>
          <p style="font-size: 22px; font-weight: bolder">
            服务城市：
          </p>
          <div class="city">
            <el-dropdown>
              <span class="el-dropdown-link"> 北京总部 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：bj@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：北京市朝阳区望京顺北大街诚盈中心12G</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 天津 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：tj@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：天津市河东区十一经路81号天星河畔广场2708室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 石家庄 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：sjz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：河北省石家庄市裕华区槐安东路145号西美SOHO写字楼706室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 太原 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：ty@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：山西省太原市小店区发展路晋阳街口鼎晨时代1214室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 包头 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：bt@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：内蒙古包头市青山区友谊大街67号传媒大厦A座1107室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 呼和浩特 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：hhht@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：内蒙古呼和浩特市新城区成吉思汗大街日新大厦B座13层1308室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 深圳 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：sz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：广东省深圳市福田区深南大道华融大厦18层05单元</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 南宁 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：nn@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：广西壮族自治区南宁市青秀区金浦路16号汇东国际F座1808</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 广州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：gz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：广东省广州市海珠区会展南五路1号5号1020房</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 东莞 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：dg@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：广东省东莞市东城街道东莞大道东城段19号鼎峰国际广场2栋1211室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 珠海 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：zh@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：广东省珠海市香洲区吉大海滨南路47号光大国际贸易中心1207房</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown>
              <span class="el-dropdown-link"> 上海 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：sh@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：上海市长宁区通协路268号尚品都汇A505</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 宁波 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：nb@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：浙江省宁波市鄞州区天童南路555号侨商大厦1502室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 嘉兴 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：jx@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：浙江省嘉兴市长水街道文桥路505号融通商务中心1号楼702室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 湖州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：hz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：浙江省湖州市吴兴区苕溪西路双子大厦604室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 义乌 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：yw@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：浙江省义乌市北苑街道望道路318号跨界园二区5楼523</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 金华 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：jh@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：浙江省金华市金华山旅游经济区赤松镇黄泥龙村19幢1号3楼</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 绍兴 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：sx@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：浙江省绍兴市越城区中兴北路601号好望大厦B座706室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 温州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：wz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：浙江省温州市车站大道2号华盟商务广场1902室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 台州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：tz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：浙江省台州市椒江区洪家洪西路1号酷车小镇C幢8204</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 杭州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：hz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：浙江省杭州市滨江区阡陌路482号智慧e谷A楼</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 南京 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：nj@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：江苏省南京市雨花区软件大道109号雨花客厅4栋1209室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 苏州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：sz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：江苏省苏州市虎丘区狮山路199号新地中心2708</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 常州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：cz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：江苏省常州市武进区武宜中路188号吾悦大厦1号楼2003室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 无锡 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：wx@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：江苏省无锡市梁溪区钟书路99号国金中心3723室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 济南 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：jn@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：山东省济南市槐荫区连城国际A地块702室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 烟台 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：yt@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：山东省烟台市芝罘区兴园路16号九七大厦1406室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 青岛 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：qd@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：山东省青岛市崂山区海尔路63号数码科技中心A座1406室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 福建 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：fj@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：福建省厦门市海沧区钟林路12号商务大厦1603室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 南昌 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：nc@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：江西省南昌市红谷滩新区红谷中大道998号绿地中央广场A1号办公楼504B室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 合肥 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：hf@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：安徽省合肥市包河区马鞍山南路660号绿地赢海国际大厦D座1608室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 厦门 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：xm@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：福建省厦门市湖里区安岭路999号901单元之4</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 福州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：fz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：福建省福州市台江区祥坂路95号富力中心C2栋1018-1019</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 泉州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：qz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：福建省泉州市晋江桥南片区百捷国际中心写字楼1702</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown>
              <span class="el-dropdown-link"> 郑州 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：zz@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：河南省郑州市郑东新区金水东路永和国际广场B-1404室
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 武汉 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：wh@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：湖北省武汉市江汉区青年路161号ICC环贸中心B座1110室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 长沙 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：cs@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：湖南省长沙市雨花区香樟路819号万坤图财富广场2栋2013室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown>
              <span class="el-dropdown-link"> 大连 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：dl@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：辽宁省大连市甘井子区中华西路18号A座626室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 长春 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：cc@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：吉林省长春市净月区生态大街和美路东行200米中懋天地2209室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <span class="el-dropdown-link"> 哈尔滨 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：hrb@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：黑龙江省哈尔滨市南岗区闽江路75号3号楼2003</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown>
              <span class="el-dropdown-link"> 西安 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>邮箱：xa@deanh.cn</el-dropdown-item>
                <el-dropdown-item
                  >地址：陕西省西安市高新区丈八街办科技四路万科金域国际A座2107室</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <div>
              <el-dropdown>
                <span class="el-dropdown-link"> 重庆 </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>邮箱：cq@deanh.cn</el-dropdown-item>
                  <el-dropdown-item
                    >地址：重庆市渝北区食品城大道18号创意文化公园F4-15栋-2A-5</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown>
                <span class="el-dropdown-link"> 成都 </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>邮箱：cd@deanh.cn</el-dropdown-item>
                  <el-dropdown-item
                    >地址：四川省成都市武侯区武兴四路166号西部智谷D区7栋2单元704</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown>
                <span class="el-dropdown-link"> 昆明 </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>邮箱：km@deanh.cn</el-dropdown-item>
                  <el-dropdown-item
                    >地址：云南省昆明市盘龙区白云路官房广场258号14楼D座</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "./Layout";
import Footer from "./footer";
export default {
  components: { Header, Footer },
  computed: {
        Src_() {
            let w = document.body.clientWidth;
            if (w > 600) {
                return require('../assets/five1.png')
            } else {
                return require('../assets/five1_2.png')
            }
        },
    }
};
</script>
<style scoped>
.el-dropdown {
  margin-right: 10px;
  /* display: flex;   
  flex-wrap: wrap; */
}
.city {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.city::v-deep span{
  display: inline-block;
  margin: 20px 0 20px 20px;
}
@media screen and (max-device-width: 600px) {

  .city{
    display: flex;
    flex-wrap: wrap;
  }
  .main-zz .text {
    color: white;
    margin-left: 0 !important;
  }
  .main {
    width: 100%;
    /* height: 600px; */
    /* background: url("../assets/405.png") no-repeat left !important; */
    background-size: cover !important;
    /* position: relative; */
  }
  .city::v-deep span{
    display: inline-block;
    margin: 8px 0 8px 8px !important;
  }
  .banner {
    height: auto !important;
  }
  .text{
    padding: 15px !important;
  }
}
@media screen and (min-width: 800px) {
    .banner{
        height: auto !important;
        margin-bottom: 0 !important;
    }
}
.el-dropdown-link {
  cursor: pointer;
  color: white;
  font-size: 20px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-menu {
  background: transparent;
}
.el-dropdown-menu .el-dropdown-menu__item {
  color: white;
  background: rgba(0, 0, 0, 0.7);
  font-size: 18px;
}
.main {
  width: 100%;
  height: 600px;
  /* background: url("../assets/map.png") no-repeat left; */
  background-size: cover;
  /* position: relative; */
  /* margin-top: 30px; */
}
.main-zz {
  width: 100%;
  height: inherit;
  /* position: absolute; */
  background: rgba(0, 0, 0, .8);
  /* border: 1px solid red; */
  /* padding: 20px 0; */
}
.main-zz .text {
  width: 95%;
  color: white;
  /* margin-left: 155px; */
  margin: 0 auto;
  padding-top: 20px;
}
</style>